export default [
  {
    path: "/request-trackings",
    name: "request-trackings",
    meta: { title: "Suivi de colis", authRequired: true },
    component: () => import("../views/request-trackings/requests"),
  },
  {
    path: "/request-details/:id?",
    name: "request-details",
    meta: { title: "Informations sur le suivi", authRequired: true },
    component: () => import("../views/request-trackings/request-details"),
  },
  {
    path: "/request-update/:id?",
    name: "request-update",
    meta: { title: "Mise à jour du tracking", authRequired: true },
    component: () => import("../views/request-trackings/update-request"),
  },
  {
    path: "/add-request",
    name: "add-request",
    meta: { title: "Ajouter un suivi de colis", authRequired: true },
    component: () => import("../views/request-trackings/add-request"),
  },
];
