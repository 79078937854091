export default [
  {
    path: "/products",
    name: "products",
    meta: { title: "Products", authRequired: true },
    component: () => import("../views/apps/ecommerce/products"),
  },
  {
    path: "/product-details/:id?",
    name: "product-details",
    meta: { title: "Product Details", authRequired: true },
    component: () => import("../views/apps/ecommerce/product-details"),
  },
  {
    path: "/product-update/:id?",
    name: "product-update",
    meta: { title: "Product Update", authRequired: true },
    component: () => import("../views/apps/ecommerce/update-product"),
  },
  {
    path: "/add-product",
    name: "add-product",
    meta: { title: "Create Product", authRequired: true },
    component: () => import("../views/apps/ecommerce/add-product"),
  },
  {
    path: "/add-product-category",
    name: "add-product-category",
    meta: { title: "Creer une nouvelle categorie", authRequired: true },
    component: () => import("../views/apps/ecommerce/add-products-category"),
  },
];
