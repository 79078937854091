import { createApp } from "vue";

// Import the functions you need from the SDKs you need

import firebase from "firebase/app";
import "firebase/storage";

import App from "./App.vue";
import router from "./router";
import AOS from "aos";
import "aos/dist/aos.css";
import i18n from "./i18n";
import store from "./state/store";

import BootstrapVue3 from "bootstrap-vue-3";
import vClickOutside from "click-outside-vue3";
import VueApexCharts from "vue3-apexcharts";
import Maska from "maska";

import VueFeather from "vue-feather";
import Particles from "particles.vue3";

import CKEditor from "@ckeditor/ckeditor5-vue";
import myUpload from "vue-image-crop-upload";

import "@/assets/scss/config/material/app.scss";
import "@vueform/slider/themes/default.css";
import Swal from "sweetalert2";

AOS.init({
  easing: "ease-out-back",
  duration: 1000,
});

const firebaseConfig = {
  apiKey: "AIzaSyBlQ9KqvchCzsM2Ef9qrYzAZCVbP50jfTU",
  authDomain: "hello-world-9f9e5.firebaseapp.com",
  projectId: "hello-world-9f9e5",
  storageBucket: "hello-world-9f9e5.appspot.com",
  messagingSenderId: "1058904130996",
  appId: "1:1058904130996:web:066652ac918efca51e9ee1",
  measurementId: "G-F9LPC4S20Q",
};

// Initialize Firebase

firebase.initializeApp(firebaseConfig);

async function uploadFile(file, name) {
  const storageRef = firebase.storage().ref();
  const snapshot = await storageRef
    .child(
      "images/" + name.trim().replace(/ /g, "-") + "-" + Date.now().toString(36)
    )
    .putString(file, "data_url");
  return await snapshot.ref.getDownloadURL();
}




function saSuccess(title, message) {
  Swal.fire({
    title: title,
    text: message,
    icon: "success",
    confirmButtonClass: "btn btn-primary w-xs mt-2",
    buttonsStyling: false,
    showCloseButton: true,
  });
}

function saError(title, message) {
  Swal.fire({
    title: title,
    text: message,
    icon: "error",
    confirmButtonClass: "btn btn-primary w-xs mt-2",
    buttonsStyling: false,
    showCloseButton: true,
  });
}

function saInfo(title, message) {
  Swal.fire({
    title: title,
    text: message,
    icon: "info",
    confirmButtonClass: "btn btn-primary w-xs mt-2",
    buttonsStyling: false,
    showCloseButton: true,
  });
}

function saStartLoading(title) {
  // Swal loading animation and indicator
  Swal.fire({
    title: title || "Chargement...",
    // Bootstrap  spinner
    html: `<div class="spinner-border text-primary" role="status"></div>`,
    allowOutsideClick: false,
    showConfirmButton: false,
  });
}

function saStopLoading() {
  Swal.close();
}

const app = createApp(App);

function formatPrice(price) {
  // Check if the price is a valid number
  if (isNaN(price)) {
      return "Invalid Price";
  }
  
  // Create a NumberFormat object with currency formatting
  const formatter = new Intl.NumberFormat('fr-FR', {
      style: 'currency',
      currency: 'XAF'
  });
  
  // Format the price using the formatter
  return formatter.format(price);
}

// Example usage
console.log(formatPrice(1234567.89)); // Output: $1,234,567.89

app.config.globalProperties.$saError = saError;
app.config.globalProperties.$formatPrice = formatPrice;
app.config.globalProperties.$saSuccess = saSuccess;
app.config.globalProperties.$saInfo = saInfo;
app.config.globalProperties.$startLoading = saStartLoading;
app.config.globalProperties.$stopLoading = saStopLoading;


app.config.globalProperties.$upload_file = uploadFile;

app
  .use(store)
  .use(router)
  .use(VueApexCharts)
  .use(CKEditor)
  .use(BootstrapVue3)
  .component(VueFeather.type, VueFeather)
  .component("my-upload", myUpload)
  .use(Maska)
  .use(Particles)
  .use(i18n)
  .use(vClickOutside)
  .mount("#app");
