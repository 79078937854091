export default [
  {
    path: "/courses",
    name: "courses",
    meta: { title: "Formations", authRequired: true },
    component: () => import("../views/courses/courses"),
  },
  {
    path: "/course-details/:id?",
    name: "course-details",
    meta: { title: "Informations sur la formation", authRequired: true },
    component: () => import("../views/courses/course-details"),
  },
  {
    path: "/course-update/:id?",
    name: "course-update",
    meta: { title: "Mise à jour de la formation", authRequired: true },
    component: () => import("../views/courses/update-course"),
  },
  {
    path: "/add-course",
    name: "add-course",
    meta: { title: "Ajouter une formation", authRequired: true },
    component: () => import("../views/courses/add-course"),
  },
  {
    path: "/add-course-category",
    name: "add-course-category",
    meta: { title: "Creer une nouvelle categorie", authRequired: true },
    component: () => import("../views/courses/add-course-category"),
  },
];
