import ImmigrationRequestServices from "@/services/ImmigrationRequestServices";

// namespaced: true,

export const state = {
  requests: [],
  current_request: {},
  loading: false,
};

export const getters = {
  requests(state) {
    return state.requests;
  },
  currentRequest(state) {
    return state.current_request;
  },
  loading(state) {
    return state.loading;
  },
};

export const actions = {
  async findAll({ commit }) {
    commit("SET_LOADING", true);
    const subscriptionsData = [];
    await ImmigrationRequestServices.getAll()
      .then((data) => {
        const monthNames = [
          "Jan",
          "Fev",
          "Mar",
          "Avr",
          "Mai",
          "Juin",
          "Juil",
          "Aout",
          "Sept",
          "Oct",
          "Nov",
          "Dec",
        ];
        data.requests.forEach((row) => {
          var dd = new Date(row.created_at);
          row.publishedDate =
            dd.getDate() +
            " " +
            monthNames[dd.getMonth()] +
            ", " +
            dd.getFullYear();
          var hours = dd.getHours();
          var minutes = dd.getMinutes();
          var ampm = hours >= 12 ? "PM" : "AM";
          hours = hours % 12;
          hours = hours ? hours : 12; // the hour '0' should be '12'
          hours = hours < 10 ? "0" + hours : hours;
          minutes = minutes < 10 ? "0" + minutes : minutes;
          var strTime = hours + ":" + minutes + " " + ampm;
          row.publishedtime = strTime;
          // row.image_src =
          //   "https://api-node.themesbrand.website/images/products/" + row.image;
          row.image_src = row.image;
          // row.image_src = `@/assets/images/products/img-8.png`;
          subscriptionsData.push(row);
        });
      })
      .catch((er) => {
        console.log(er);
      });

    commit("SET_REQUESTS", subscriptionsData);
    commit("SET_LOADING", false);
  },
  async findOne({ commit }, requestId) {
    commit("SET_LOADING", true);
    const { data } = await ImmigrationRequestServices.fetchOne(requestId);
    commit("SET_CURRENT_REQUEST", data.data.request);
    commit("SET_LOADING", false);
  },
  async setCurrentRequest({ commit }, campaign) {
    commit("SET_CURRENT_REQUEST", campaign);
  },
  setLoading({ commit }, loading) {
    commit("SET_LOADING", loading);
  },
};

export const mutations = {
  SET_REQUESTS(state, requests) {
    state.requests = requests;
  },
  SET_CURRENT_REQUEST(state, request) {
    state.current_request = request;
  },
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
};
