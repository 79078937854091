import Axios from "../config/axios";

export default {
  getAll() {
    return new Promise((resolve, reject) => {
      Axios.get("/admin/processes")
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getById(id) {
    return new Promise((resolve, reject) => {
      Axios.get("/admin/processes/" + id + "/show")
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  delete(id) {
    return new Promise((resolve, reject) => {
      Axios.delete("/admin/processes/" + id + "/delete")
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  createTransaction(id, step_id, data) {
    return new Promise((resolve, reject) => {
      Axios.post(
        "/admin/processes/" + id + "/steps/" + step_id + "/create-transaction",
        data
      )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  sendDocument(id, data) {
    return new Promise((resolve, reject) => {
      Axios.put("/admin/processes/" + id + "/send-document", data, {
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  updateDocument(id, documentId, data) {
    return new Promise((resolve, reject) => {
      Axios.put(
        "/admin/processes/" + id + "/documents/" + documentId + "/update",
        data,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  confirmStep(id, stepId) {
    return new Promise((resolve, reject) => {
      Axios.patch(
        "/admin/processes/" + id + "/steps/" + stepId + "/confirm-step",
        {}
      )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  defineStepPrice(id, stepId, data) {
    return new Promise((resolve, reject) => {
      Axios.patch(
        "/admin/processes/" + id + "/steps/" + stepId + "/define-price",
        data
      )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  confirmDocument(id, documentId) {
    return new Promise((resolve, reject) => {
      Axios.patch(
        "/admin/processes/" + id + "/documents/" + documentId + "/confirm",
        {}
      )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  rejectDocument(id, documentId) {
    return new Promise((resolve, reject) => {
      Axios.patch(
        "/admin/processes/" + id + "/documents/" + documentId + "/reject",
        {}
      )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  confirmAllDocuments(id) {
    return new Promise((resolve, reject) => {
      Axios.patch("/admin/processes/" + id + "/documents/confirm-all", {})
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
