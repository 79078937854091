export default [
  {
    path: "/immigration-requests",
    name: "immigration-requests.list",
    meta: { title: "Liste des requêtes d'immigration", authRequired: true },
    component: () => import("../views/immigration-requests/list"),
  },
  {
    path: "/immigration-requests/:id?/view",
    name: "immigration-requests.details",
    meta: { title: "Détails sur la requête", authRequired: true },
    component: () => import("../views/immigration-requests/details"),
  },
  {
    path: "/immigration-requests/:id?/steps/:stepId?/view",
    name: "immigration-requests.steps.details",
    meta: { title: "Détails sur l'étape", authRequired: true },
    component: () => import("../views/immigration-requests/details-step"),
  },
];
