import Axios from "@/config/axios";

export default {
  getAll() {
    return new Promise((resolve, reject) => {
      Axios.get("/admin/request-trackings")
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getById(id) {
    return new Promise((resolve, reject) => {
      Axios.get("/admin/request-trackings/" + id + "/show")
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  create(data) {
    return new Promise((resolve, reject) => {
      Axios.post("/admin/request-trackings/create", data)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  update(requestId, data) {
    return new Promise((resolve, reject) => {
      Axios.put("/admin/request-trackings/" + requestId + "/update", data)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  createStep(requestId, data) {
    return new Promise((resolve, reject) => {
      Axios.post(
        "/admin/request-trackings/" + requestId + "/steps/create",
        data
      )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  updateStep(requestId, stepId, data) {
    return new Promise((resolve, reject) => {
      Axios.put(
        "/admin/request-trackings/" +
          requestId +
          "/steps/" +
          stepId +
          "/update",
        data
      )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  confirmStep(requestId, stepId, data) {
    return new Promise((resolve, reject) => {
      Axios.patch(
        "/admin/request-trackings/" +
          requestId +
          "/steps/" +
          stepId +
          "/finish",
        data
      )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  notify(requestId, data) {
    return new Promise((resolve, reject) => {
      Axios.put("/admin/request-trackings/" + requestId + "/notify", data)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
