import RequestTrackingServices from "@/services/RequestTrackingServices";

// namespaced: true,

export const state = {
  requests: [],
  current_request: {},
  loading: false,
};

export const getters = {
  requests(state) {
    return state.requests;
  },
  currentRequest(state) {
    return state.current_request;
  },
  loading(state) {
    return state.loading;
  },
};

export const actions = {
  async findAll({ commit }) {
    commit("SET_LOADING", true);
    let orders = [];
    await RequestTrackingServices.getAll()
      .then((data) => {
        const monthNames = [
          "Jan",
          "Fev",
          "Mar",
          "Avr",
          "Mai",
          "Juin",
          "Juil",
          "Aou",
          "Sept",
          "Oct",
          "Nov",
          "Dec",
        ];
        data.trackings.forEach((row) => {
          var dd = new Date(row.data.registered_at);
          row.registeredDate =
            dd.getDate() +
            " " +
            monthNames[dd.getMonth()] +
            ", " +
            dd.getFullYear();
          orders.push(row);
        });
      })
      .catch((er) => {
        console.log(er);
      });

    commit("SET_REQUESTS", orders);
    commit("SET_LOADING", false);
  },
  async findOne({ commit }, requestId) {
    commit("SET_LOADING", true);
    const { data } = await RequestTrackingServices.fetchOne(requestId);
    commit("SET_CURRENT_REQUEST", data.data.request);
    commit("SET_LOADING", false);
  },
  async setCurrentRequest({ commit }, campaign) {
    commit("SET_CURRENT_REQUEST", campaign);
  },
  setLoading({ commit }, loading) {
    commit("SET_LOADING", loading);
  },
};

export const mutations = {
  SET_REQUESTS(state, requests) {
    state.requests = requests;
  },
  SET_CURRENT_REQUEST(state, request) {
    state.current_request = request;
  },
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
};
