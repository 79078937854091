export default [
  {
    path: "/buy-requests",
    name: "buy-requests.list",
    meta: { title: "Liste des requêtes d'immigration", authRequired: true },
    component: () => import("../views/buy-requests/list"),
  },
  {
    path: "/buy-requests/:id?/view",
    name: "buy-requests.details",
    meta: { title: "Détails sur la requête", authRequired: true },
    component: () => import("../views/buy-requests/details"),
  },
];
